import { TYPE_BROADCAST } from './types';
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*
  `,
  [TYPE_BROADCAST.CUSTOM_INVITATION]: `*_CJ LOUIS & CHRISTY WEDDING_* 

Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*
  
Perkenalkan, kami dari *Invitato* mewakili keluarga pengantin ingin menyampaikan undangan pernikahan: 
  
*Caesar Joshua Louis Panjaitan & Christy Raina Septiningrum Mamesah*
  
*Mohon klik link undangan di bawah untuk informasi lebih lanjut:*
{{LINK}}
_*Jika link tidak berwarna biru/tidak muncul silakan untuk coba membalas chat ini dengan "YA", atau simpan nomor ini untuk dapat membuka link.*_

*Acara pernikahan akan diselenggarakan pada:* 
Hari: Sabtu, 26 Agustus 2023
Waktu: 16.00 - 18.00 WIB
Lokasi: Galea Belangi, Bekasi
  
*KONFIRMASI KEHADIRAN:*
1. Apakah Bapak/Ibu/Saudara/i berkenan hadir?
_Jawab YA/TIDAK_
2. Apabila hadir, berapa jumlah tamu yang akan menghadiri acara? 
_Jawab dengan angka_
  
Konfirmasi Bapak/Ibu/Saudara/i kami tunggu maksimal sampai tanggal *21 Agustus 2023*
  
Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga.
  
Mohon sampaikan konfirmasi kehadiran Anda dengan cara membalas pesan ini.
  
Terima kasih.
  
_Kami yang berbahagia,_
*CJ Louis, Christy & keluarga*
    `,
  CUSTOM_REMINDER: `Selamat siang, Bapak/Ibu, {{GUEST_NAME}} 😊🙏🏻 
  
  Mewakili keluarga *Caesar Joshua Louis Panjaitan & Christy Raina Septiningrum Mamesah*, kami ingin mengingatkan batas maksimal konfirmasi kehadiran yaitu hari ini, 21 Agustus 2023 . 
    
  Apakah Bapak/Ibu dapat menghadiri acara pernikahan *CJ Louis & Christy*?  
    
  Kami tunggu segera konfirmasinya Bapak/Ibu supaya bisa kami catat dan sampaikan ke keluarga pengantin.  
  Terima kasih.`,
  CUSTOM_REMINDER_EVENT: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

Mewakili keluarga pengantin, kami dari *Invitato* ingin mengingatkan undangan pernikahan *Caesar Joshua Louis Panjaitan & Christy Raina Septiningrum Mamesah*.

*Acara pernikahan akan diselenggarakan pada:* 
Hari: Sabtu, 26 Agustus 2023
Waktu: 16.00 - 18.00 WIB
Lokasi: Galea Belangi, Bekasi

Berikut kami kirimkan *QR Code* sebagai tanda masuk saat registrasi di acara dan berlaku untuk *{{TOTAL_GUEST}} orang*.

Terimakasih atas perhatian dan dukungan kehadiran untuk pernikahan CJ Louis & Christy.

_Kami yang berbahagia,_
*CJ Louis, Christy & keluarga*`,
};
